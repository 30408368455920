<template>
  <div>
    <b-navbar
      toggleable="lg"
      class="navbar navbar-expand-lg navbar-dark fixed-top p-2 px-4"
      type="light"
    >
      <b-navbar-brand to="/" class="">
        <img src="@/assets/pengaduan/assets/images/sijaga_01_1.png" class="logo" />
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ms-auto navbar-white-text">
          <b-nav-item to="/pengaduan/home">Beranda</b-nav-item>
          <b-nav-item to="/pengaduan/list">Pengaduan</b-nav-item>
          <b-nav-item href="/pdf/BUKU_PANDUAN_SIJAGA.pdf" download="BUKU_PANDUAN_SIJAGA.pdf" variant="success" class="ms-2">
            Buku Panduan
          </b-nav-item>
          <!-- <b-nav-item
            href="https://orionms.maps.arcgis.com/apps/webappviewer/index.html?id=9de91ac4071441d793ec136ab52d2084"
            >Database Irigasi</b-nav-item
          > -->
        </b-navbar-nav>
      </b-collapse>
      <b-navbar-nav class="ms-auto d-flex justify-item-end">
        <b-nav-item to="/admin/login" link-classes="btn btn-primary text-white">
          Login
        </b-nav-item>
      </b-navbar-nav>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: "Navbar",

  data() {
    return {
      token: null,
    };
  },
  methods: {
    logout() {
      localStorage.clear();
      this.$router.replace("/");
    },
  },
  computed: {
    checkToken() {
      return this.$store.getters["auth/isLoggedIn"];
    },
  },
  watch: {
    checkToken(val) {
      this.token = val;
      // console.log(this.token);
    },
  },
  mounted() {
    this.token = this.$store.getters["auth/isLoggedIn"];
  },
};
</script>

<style>
.logo {
  width: 100px;
}

.navbar-dark {
  background-color: #036163 !important;
}

.navbar-white-text .nav-link {
  color: white !important;
}
</style>
